import { getApiError } from '../utils/Api';

const MacroRepository = function (axiosInstance) {
  let _MacroRepository = {
    getMacroGraph: async function (_key, _, params) {
      try {
        const response = await axiosInstance.get(`/Macro/graph`, { params });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getAvailableSectorIndicators: async function () {
      try {
        const response = await axiosInstance.get(`/Macro/sector-indicators`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getSectorMacroPlanDetails: async function (key, sector) {
      try {
        const response = await axiosInstance.get(`/Macro/sector-frequencies/${sector}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    createSectorMacroPlan: async function (data) {
      try {
        const response = await axiosInstance.post(`/Macro/datapoint`, data);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    getEUDatapoint: async function (_key, params) {
      try {
        const response = await axiosInstance.get(`/website/unemployment`, { params });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    getEUPublication: async function (_key, { year, option }) {
      try {
        const response = await axiosInstance.get(`/website/unemployment/${year}/${option}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    deleteEURecord: async function (_key, { year, option }) {
      try {
        const response = await axiosInstance.delete(`/website/unemployment/${year}/${option}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    createEURecord: async function (_key, { postYear, postValue }) {
      try {
        const response = await axiosInstance.post(`/website/unemployment`, {
          year: postYear,
          value: postValue,
        });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    editEURecord: async function (_key, { postYear, postValue, id }) {
      try {
        const response = await axiosInstance.patch(`/website/unemployment/${id}`, {
          year: postYear,
          value: postValue,
        });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    bulkGDPUpload: async function (data) {
      try {
        const response = await axiosInstance.post(`/website/gdp`, data);
        return response.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    getGDPDatapoint: async function (_key, params) {
      try {
        const response = await axiosInstance.get(`/website/GDP`, { params });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    getGDPPublication: async function (_key, { year, option }) {
      try {
        const response = await axiosInstance.get(`/website/gdp/${year}/${option}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    deleteGDPRecord: async function (_key, { year, option }) {
      try {
        const response = await axiosInstance.delete(`/website/gdp/${year}/${option}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    deleteGDPQuarterRecord: async function (_key, { year, quarter, option }) {
      try {
        const response = await axiosInstance.delete(`/website/gdp/${year}/${quarter}/${option}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    getGDPDataByYear: async function (_key, params) {
      try {
        const response = await axiosInstance.get(`/website/gdp/${params}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    getGDPQuarterPublication: async function (_key, { year, quarter, option }) {
      try {
        const response = await axiosInstance.get(`/website/gdp/${year}/${quarter}/${option}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    getCPIDatapoint: async function (_key, params) {
      try {
        const response = await axiosInstance.get(`/website/cpi`, { params });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    getCPIPublication: async function (_key, { year, option }) {
      try {
        const response = await axiosInstance.get(`/website/cpi/${year}/${option}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    getCPIMonthPublication: async function (_key, { year, option, month }) {
      try {
        const response = await axiosInstance.get(`/website/cpi/${year}/${month}/${option}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getCPIByYear: async function (_key, { year }) {
      try {
        const response = await axiosInstance.get(`/website/cpi/${year}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    createCPIRecord: async function (_key, { postYear, postValue, month }) {
      try {
        const response = await axiosInstance.post(`/website/cpi`, {
          year: postYear,
          value: postValue,
          month,
        });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    deleteCPIRecord: async function (_key, { year, option }) {
      try {
        const response = await axiosInstance.delete(`/website/cpi/${year}/${option}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    getSector: async function () {
      try {
        const response = await axiosInstance.get(`/Macro/sector-indicators`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    getKeyIndicator: async function () {
      try {
        const response = await axiosInstance.get(`/website/key-indicators`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    deleteKeyIndicatorRecord: async function (_key, { title }) {
      try {
        const response = await axiosInstance.delete(`/website/key-indicators/${title}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    getKeyIndicatorDataByYear: async function (_key, params) {
      try {
        const response = await axiosInstance.get(`/website/key-indicators/${params}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    getKeyIndicatorPublication: async function (_key, { title, option }) {
      try {
        const response = await axiosInstance.get(`/website/key-indicators/${title}/${option}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    getCbnIndicator: async function () {
      try {
        const response = await axiosInstance.get(`/website/cbn-indicator`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    createKeyIndicator: async function (data) {
      try {
        const response = await axiosInstance.post('/website/key-indicators', data);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    editKeyIndicator: async function (data) {
      try {
        const response = await axiosInstance.patch('/website/key-indicators', data);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    EditCBNIndicator: async function ({ data, id }) {
      const newData = { date: data.date, value: data.value };
      try {
        const response = await axiosInstance.patch(`/website/cbn-indicator/${id}`, newData);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    deleteUser: async function (id) {
      try {
        const response = await axiosInstance.delete(`/users/${id}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    getRevenuePerState: async function (_key, params) {
      try {
        const response = await axiosInstance.get(`/website/revenue`, { params });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    deleteRevenuePerStateRecord: async function (_key, { year, option }) {
      try {
        const response = await axiosInstance.delete(`/website/revenue/${year}/${option}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getRevenuePerStatePublication: async function (_key, { year, option }) {
      try {
        const response = await axiosInstance.get(`/website/revenue/${year}/${option}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    bulkRevenuePerStateUpload: async function (data) {
      console.log('posts', data);
      try {
        const response = await axiosInstance.post(`/website/revenue`, data);
        return response.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
  };
  return _MacroRepository;
};

export { MacroRepository };
