import { getApiError, parseComplexQueryParams } from 'utils/Api';

const BudgetRepository = function (axiosInstance) {
  let _BudgetRepository = {
    getAllBudgets: async function (_key, params) {
      try {
        const response = await axiosInstance.get('/budget/', { params });
        return response.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getBudgetById: async function (_key, id) {
      try {
        const response = await axiosInstance.get(`/budget/${id}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getBudgetStats: async function (_key, params) {
      try {
        const response = await axiosInstance.get('/budget/year', { params });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    uploadSubMDABudgetBreakdown: async function (data) {
      try {
        const response = await axiosInstance.post(`/budget/upload`, data);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    uploadSubMDAProjectBreakdown: async function (data) {
      try {
        const response = await axiosInstance.post(`/budget/upload-project`, data);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    previewBudget: async function (_key, _, year) {
      try {
        const response = await axiosInstance.get(`/budget/preview/${year}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    publishBudget: async function (data) {
      try {
        const response = await axiosInstance.post(`/budget/publish`, data);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getMdaDetails: async function (_key, _type, params) {
      try {
        const response = await axiosInstance.get('/budget/mda-details', { params });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getBudgetYearlyTrend: async function (_key) {
      try {
        const response = await axiosInstance.get('/budget/yearly-stats');
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getSubMdaBudgets: async function (_key, mdaCode, year, params) {
      try {
        const response = await axiosInstance.get(`/budget/subMda-by-mdaCode/${mdaCode}/${year}`, {
          params,
        });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getSubMdaTrendById: async function (_key, _type, params) {
      try {
        const response = await axiosInstance.get('/budget/subMDA-trend-by-Id', { params });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getBudgetComparisonMdas: async (_, _key, params) => {
      try {
        const response = await axiosInstance.get(`/budget/comparison-mda/`, {
          params: parseComplexQueryParams(params),
        });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getNewProjectsComparisonMda: async (_, _key, params) => {
      try {
        const response = await axiosInstance.get(`/budget/new-project-comparison-mda/`, {
          params: parseComplexQueryParams(params),
        });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getOngoingProjectsComparisonMda: async (_, _key, params) => {
      try {
        const response = await axiosInstance.get(`/budget/ongoing-project-comparison-mda/`, {
          params: parseComplexQueryParams(params),
        });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getBudgetComparisonMdasByYear: async (_, _key, params) => {
      try {
        const response = await axiosInstance.get(`/budget/comparison-mda-year/`, {
          params: parseComplexQueryParams(params),
        });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getNewProjectsComparisonMdaByYear: async (_, _key, params) => {
      try {
        const response = await axiosInstance.get(`/budget/new-project-comparison-year/`, {
          params: parseComplexQueryParams(params),
        });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getOngoingProjectsComparisonMdaByYear: async (_, _key, params) => {
      try {
        const response = await axiosInstance.get(`/budget/ongoing-project-comparison-year/`, {
          params: parseComplexQueryParams(params),
        });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getBudgetComparisonSubMdas: async (_, _key, params) => {
      try {
        const response = await axiosInstance.get(`/budget/comparison-subMDA`, {
          params: parseComplexQueryParams(params),
        });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getNewProjectsComparisonSubMdas: async (_, _key, params) => {
      try {
        const response = await axiosInstance.get(`/budget/new-project-comparison-subMDA`, {
          params: parseComplexQueryParams(params),
        });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getOngoingProjectsComparisonSubMdas: async (_, _key, params) => {
      try {
        const response = await axiosInstance.get(`/budget/ongoing-project-comparison-subMDA`, {
          params: parseComplexQueryParams(params),
        });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getBudgetComparisonSubMdasByYear: async (_, _key, params) => {
      try {
        const response = await axiosInstance.get(`/budget/comparison-subMDA-year`, {
          params: parseComplexQueryParams(params),
        });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getNewProjectsComparisonSubMdasByYear: async (_, _key, params) => {
      try {
        const response = await axiosInstance.get(`/budget/new-project-comparison-subMDA-year`, {
          params: parseComplexQueryParams(params),
        });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getOngoingProjectsComparisonSubMdasByYear: async (_, _key, params) => {
      try {
        const response = await axiosInstance.get(`/budget/ongoing-project-comparison-subMDA-year`, {
          params: parseComplexQueryParams(params),
        });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
  };
  return _BudgetRepository;
};

export { BudgetRepository };
