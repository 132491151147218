import { getApiError } from '../utils/Api';

const SectorRepository = function (axiosInstance) {
  let _SectorRepository = {
    getAllSectors: async function (params) {
      try {
        const response = await axiosInstance.get('/sectors', { params });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    createDataPoint: async function (params, data) {
      try {
        const response = await axiosInstance.post('/Macro/datapoint', params);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    getDataPointFrequency: async function (_, id) {
      try {
        const response = await axiosInstance.get(`/Macro/sector-frequencies/${id}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
  };
  return _SectorRepository;
};

export { SectorRepository };
