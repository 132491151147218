import { getApiError } from '../utils/Api';

const MDARepository = function (axiosInstance) {
  let _MDARepository = {
    getMDAs: async function (_key, params) {
      try {
        const response = await axiosInstance.get('/MDA/', { params });
        return response.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getSubMDAs: async function (_key, params) {
      try {
        const response = await axiosInstance.get('/MDA/sub-mdas/', { params });
        return response.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getSubMDAsById: async function (_key, id, params) {
      try {
        const response = await axiosInstance.get(`/MDA/${id}/sub-mdas`, { params });
        return response.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    updateMDA: async function ({ data, id }) {
      try {
        const response = await axiosInstance.patch(`/MDA/${id}`, data);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    updateSubMDA: async function ({ data, id }) {
      try {
        const response = await axiosInstance.patch(`/MDA/sub-mdas/${id}`, data);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    uploadMDAFile: async function (data) {
      try {
        const response = await axiosInstance.post(`/MDA/upload`, data);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    createMDA: async function (data) {
      try {
        const response = await axiosInstance.post(`/MDA`, data);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    createSubMDA: async function (data) {
      try {
        const response = await axiosInstance.post(`/MDA/sub-mdas`, data);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getMDASubMdas: async function (_key, _, mdaCode, year, params) {
      try {
        const response = await axiosInstance.get(`/budget/subMda-by-mdaCode/${mdaCode}/${year}`, {
          params,
        });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getMdaStatByCode: async function (_key, _, params) {
      try {
        const response = await axiosInstance.get(`/budget/mda-Stats-by-Code`, { params });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getTop5SubMDA: async function (_key, _, params) {
      try {
        const response = await axiosInstance.get(`/budget/top-5-subMDA`, { params });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getTotalMdaProjectCount: async function (_key, _type, params) {
      try {
        const response = await axiosInstance.get('/budget/total-project', { params });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getMDABudgetTrend: async function (_key, _, params) {
      try {
        const response = await axiosInstance.get(`/budget/mda-trend`, { params });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
  };
  return _MDARepository;
};

export { MDARepository };
