import React, { lazy, Suspense } from 'react';
import { Router, Switch, Redirect, Route } from 'react-router-dom';

import history from './history';
import DelayedFallback from '../components/DelayedFallback';

const Paths = {
  COMMITTEEE: '/committee',
  CITIZENS: '/citizens',
};

const Routes = () => {
  return (
    <Suspense fallback={<DelayedFallback />}>
      <Router history={history}>
        <Switch>
          <Route
            path={Paths.COMMITTEEE}
            exact={false}
            component={lazy(() => import('./CommitteeRoutes'))}
          />
          <Route
            path={Paths.CITIZENS}
            exact={false}
            component={lazy(() => import('./CitizensRoutes'))}
          />
          <Redirect to={Paths.CITIZENS} />
        </Switch>
      </Router>
    </Suspense>
  );
};

export default Routes;
