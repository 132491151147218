import { getApiError } from '../utils/Api';

const CommitteeRepository = function (axiosInstance) {
  let _CommitteeRepository = {
    getCommittees: async function (_key, params) {
      try {
        const response = await axiosInstance.get('/committee/', { params });
        return response.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getSingleCommittee: async function (_key, committeeId) {
      try {
        const response = await axiosInstance.get(`/committee/${committeeId}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getCommitteeMembers: async function (_key, committeeId) {
      try {
        const response = await axiosInstance.get(`/committee/${committeeId}/members`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getCommitteeDocuments: async function (_key, committeeId, params) {
      try {
        const response = await axiosInstance.get(`/workflow/committee/${committeeId}`, { params });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
  };
  return _CommitteeRepository;
};

export { CommitteeRepository };
