import React from 'react';
import ReactDOM from 'react-dom';
import { ReactQueryConfigProvider } from 'react-query';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const queryConfig = {
  queries: {
    refetchOnWindowFocus: false,
    retry: 1,
  },
  mutations: {
    throwOnError: false,
  },
};

ReactDOM.render(
  <ReactQueryConfigProvider config={queryConfig}>
    <App />
  </ReactQueryConfigProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
