import { getApiError } from '../utils/Api';

const SubMDARepository = function (axiosInstance) {
  let _SubMDARepository = {
    getSubMDAStats: async function (_key, params) {
      try {
        const response = await axiosInstance.get('/budget/subMDA-details', { params });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getTop5SubMdaProjects: async function (_key, _, params) {
      try {
        const response = await axiosInstance.get('/budget/top5-projects', { params });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getSubMdaProjectStats: async function (_key, _, params) {
      try {
        const response = await axiosInstance.get('/budget/subMDA-project-count', { params });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getSubMdaBudgetTrend: async function (_key, _, params) {
      try {
        const response = await axiosInstance.get('/budget/sub-mda-trend', { params });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getAllSubMdaProjects: async function (_key, _, subMDACode, params) {
      try {
        const response = await axiosInstance.get(`/budget/project-by-subMdaCode/${subMDACode}`, {
          params,
        });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
  };
  return _SubMDARepository;
};

export { SubMDARepository };
