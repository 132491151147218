import { CancelToken } from 'axios';
import { getApiError } from '../utils/Api';

const MemorandumRepository = function (axiosInstance) {
  let cancelFetchMemoRequest = null;
  let _MemorandumRepository = {
    createMemo: async function (data) {
      try {
        const response = await axiosInstance.post('/memo/', data);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getMemos: async function (_key, params) {
      cancelFetchMemoRequest && cancelFetchMemoRequest();
      try {
        const response = await axiosInstance.get('/memo/', {
          params,
          cancelToken: new CancelToken(function executor(c) {
            cancelFetchMemoRequest = c;
          }),
        });
        return response.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getSubmissionsMonthlyStat: async function (_key, year) {
      try {
        const response = await axiosInstance.get(`/memo/${year}/monthly-stats/`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getHighestSubmittingSectorStat: async function (params) {
      try {
        const response = await axiosInstance.get('/memo/highest-submission-sector', { params });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getTopSubmissions: async function (params) {
      try {
        const response = await axiosInstance.get('/memo/top-submission', { params });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getTotalSubmissions: async function (_key, _, params) {
      try {
        const response = await axiosInstance.get('/memo/total-submissions', { params });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getMemoDetail: async function (_key, memoId) {
      try {
        const response = await axiosInstance.get(`/memo/${memoId}/`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
  };
  return _MemorandumRepository;
};

export { MemorandumRepository };
