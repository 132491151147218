import React from 'react';

import Routes from './routes';
import { useAuthenticatedUser } from './hooks/useAuthenticatedUser';
import DelayedFallback from './components/DelayedFallback';
import Auth from './utils/Auth';

const App = () => {
  const { userDetails } = useAuthenticatedUser();

  const renderCommitteeRoutes = () => {
    return !!userDetails || !Auth.isAuthenticated() ? <Routes /> : <DelayedFallback />;
  };

  return Auth.belongsToCitizensRoutes() ? <Routes /> : renderCommitteeRoutes();
};

export default App;
