/** @format */

import axios from 'axios';
import { BudgetRepository } from 'repository/BudgetRepository';
import { MDARepository } from 'repository/MDARepository';
import { AuthRepository } from '../repository/AuthRepository';
import { CommentRepository } from '../repository/CommentRepository';
import { CommitteeRepository } from '../repository/CommitteeRepository';
import { MacroRepository } from '../repository/MacroRepository';
import { MemorandumRepository } from '../repository/MemorandumRepository';
import { NotificationRepository } from '../repository/NotificationRepository';
import { OverviewRepository } from '../repository/OverviewRepository';
import { SectorRepository } from '../repository/SectorRepository';
import { SubMDARepository } from '../repository/SubMDARepository';
import { UserRepository } from '../repository/UserRepository';
import { WorkspaceRepository } from '../repository/WorkspaceRepository';
import history from '../routes/history';
import Auth from './Auth';
import { UserType } from './Constants';

const config = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
};

const instance = axios.create(config);

if (Auth.isAuthenticated() === true && !Auth.belongsToCitizensRoutes()) {
  instance.defaults.headers.common['Authorization'] = `Bearer ${Auth.getAccessToken()}`;
}

export const Api = {
  AuthRepository: AuthRepository(instance),
  MemorandumRepository: MemorandumRepository(instance),
  SectorRepository: SectorRepository(instance),
  CommentRepository: CommentRepository(instance),
  WorkspaceRepository: WorkspaceRepository(instance),
  OverviewRepository: OverviewRepository(instance),
  UserRepository: UserRepository(instance),
  CommitteeRepository: CommitteeRepository(instance),
  NotificationRepository: NotificationRepository(instance),
  MDARepository: MDARepository(instance),
  SubMDARepository: SubMDARepository(instance),
  BudgetRepository: BudgetRepository(instance),
  MacroRepository: MacroRepository(instance),
};

export const getApiError = (error) => {
  if (!!error.response === false) {
    return { status: 1, message: error?.message, details: null };
  }
  if (error.response?.status === 401) {
    Auth.removeToken();
    history.push(`/${UserType.COMMITTEE}/login`); //We do not use path variables here because lazy wouldn't be effective anymore for CommitteeRoutes
  }
  return {
    status: error?.response?.status,
    message: error?.response?.data?.message || '',
    details: error?.response?.data?.data || null,
  };
};

export const parseComplexQueryParams = (queryParams) => {
  let params = new URLSearchParams();
  Object.keys(queryParams).forEach((key) => {
    if (queryParams[key] instanceof Array) {
      queryParams[key].forEach((item) => {
        params.append(key, item);
      });
    } else {
      params.set(key, queryParams[key]);
    }
  });
  return params;
};

// resources contains all resources used within the app in making queries
// @N:B - The [CURRENT_USER] resource isn't necessarily a resource
// but its a breakout from the users resource since its information might be accessed quite often and
// we want to avoid hardcoding its key in every query instance
// so we see it as a special resource
export const resources = {
  SECTORS: `sectors`,
  MEMORANDUMS: `memorandums`,
  WORKFLOW: `workflow`,
  COMMENTS: `comments`,
  USERS: `users`,
  CURRENT_USER: `current_user`,
  COMMITTEES: `committees`,
  NOTIFICATIONS: `notifications`,
  MDAS: `mdas`,
  SUB_MDAS: `sub_mdas`,
  BUDGETS: `budgets`,
  MACROS: `macros`,
  MACROS_GDP: `macros-gdp`,
  MACROS_GDP_YEAR: `macros-gdp-year`,
  MACROS_Unemploy: `macros-unemploy`,
  MACROS_CPI: `macros-cpi-edit`,
  MACROS_KeyINDICATOR: `key_indicator`,
  MACROS_CBNINDICATOR: `cbn_indicator`,
};
