import { UserType } from './Constants';

//@todo: Remove tokens from localStorage as its susceptible to xss attack
export default class Auth {
  static isAuthenticated() {
    const token = Auth.getToken();
    return token?.access?.expiresIn && new Date() < new Date(token?.access?.expiresIn)
      ? true
      : false;
  }

  static setToken(token) {
    localStorage.setItem('token', JSON.stringify(token));
  }

  static getToken() {
    let token = localStorage.getItem('token');
    return token ? JSON.parse(token) : null;
  }

  static getAccessToken() {
    return Auth.getToken()?.access?.token || null;
  }

  static removeToken() {
    localStorage.removeItem('token');
  }

  static belongsToCitizensRoutes() {
    const pathname = window.location.pathname?.split('/')[1];
    return pathname === UserType.CITIZENS || pathname === '' || pathname === undefined;
  }
}
