import Auth from '../utils/Auth';
import { getApiError } from '../utils/Api';

const AuthRepository = function (axiosInstance) {
  let _AuthRepository = {
    login: async function (data) {
      try {
        const response = await axiosInstance.post('/auth/login/', data);
        //@todo Handle refresh token
        const {
          data: { access, refresh, user },
        } = response.data;
        Auth.setToken({ access, refresh });
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${access?.token}`;
        return user;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getCurrentUser: async function () {
      try {
        const response = await axiosInstance.get('/auth/me');
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    forgotPassword: async function (data) {
      try {
        const response = await axiosInstance.post('/auth/create-reset-password', data);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    resetPassword: async function (data) {
      try {
        const response = await axiosInstance.post('/auth/reset-password/', data);
        return response.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
  };
  return _AuthRepository;
};

export { AuthRepository };
