import { getApiError } from '../utils/Api';

const OverviewRepository = function (axiosInstance) {
  let _OverviewRepository = {
    getCommitteeStats: async function () {
      try {
        const response = await axiosInstance.get('/homepage/stats');
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getTopCommittee: async function () {
      try {
        const response = await axiosInstance.get('/homepage/TopCommitteeNationalPlan');
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getTop5MDA: async function (_key, _, params) {
      try {
        const response = await axiosInstance.get('/budget/top-5-MDA', { params });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getGdp: async function () {
      try {
        const response = await axiosInstance.get('/website/get-gdp');
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getCpi: async function () {
      try {
        const response = await axiosInstance.get('/website/get-cpi');
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getUnemployment: async function () {
      try {
        const response = await axiosInstance.get('/website/get-unemployment');
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getCbnIndicator: async function () {
      try {
        const response = await axiosInstance.get('/website/get-CbnIndicator');
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getKeyIndicator: async function () {
      try {
        const response = await axiosInstance.get('/website/get-KeyIndicator');
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getTotalRevenue: async function (_key, _, params) {
      try {
        const response = await axiosInstance.get(
          `/website/get-TotalRevenue?year=${params.totalRevenuesyear}`,
        );
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    getTotalRevenueComparision: async function (_key, _, params) {
      let str = '';

      const firstItem = Object.keys(params)[0];

      for (const key in params) {
        if (key === 'columns') {
          for (const column of params[key]) {
            str += `&columns=${column}`;
          }
        } else {
          key === firstItem ? (str += `${key}=${params[key]}`) : (str += `&${key}=${params[key]}`);
        }
      }

      try {
        const response = await axiosInstance.get(`/website/compare/?${str}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    getTrendGraph: async function () {
      try {
        const response = await axiosInstance.get('/website/trend-graph');
        return response.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
    // getYears: async function () {
    //   try {
    //     const response = await axiosInstance.get('/website/trend-graph/${years}');
    //     return response.data;
    //   } catch (error) {
    //     throw getApiError(error);
    //   }
    // },
  };
  return _OverviewRepository;
};

export { OverviewRepository };
