import { getApiError } from '../utils/Api';

const NotificationRepository = function (axiosInstance) {
  let _NotificationRepository = {
    getNotifications: async function (_key, params) {
      try {
        const response = await axiosInstance.get('/notification', { params });
        return response.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    markAsRead: async function (data) {
      try {
        const response = await axiosInstance.post('/notification/mark-as-read', data);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
  };
  return _NotificationRepository;
};

export { NotificationRepository };
