import { getApiError } from '../utils/Api';

const UserRepository = function (axiosInstance) {
  let _UserRepository = {
    completeBio: async function (data) {
      try {
        const response = await axiosInstance.post('/users/complete-bio', data);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getUsers: async function (_key, params) {
      try {
        const response = await axiosInstance.get('/users/', { params });
        return response.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    suspendUser: async function (id) {
      try {
        const response = await axiosInstance.patch(`/users/${id}/suspend`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    unSuspendUser: async function (id) {
      try {
        const response = await axiosInstance.patch(`/users/${id}/unsuspend`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    deleteUser: async function (id) {
      try {
        const response = await axiosInstance.delete(`/users/${id}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    createUser: async function (data) {
      try {
        const response = await axiosInstance.post('/users/', data);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    editUser: async function ({ data, id }) {
      try {
        const response = await axiosInstance.patch(`/users/${id}`, data);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getUserDocuments: async function (_key, userId) {
      try {
        const response = await axiosInstance.get(`/workflow/user-documents/${userId}`);
        return response.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    bulkUserUpload: async function (data) {
      try {
        const response = await axiosInstance.post(`/users/upload`, data);
        return response.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
  };
  return _UserRepository;
};

export { UserRepository };
