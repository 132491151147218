import { getApiError } from '../utils/Api';
import { CommentType } from '../utils/Constants';

const CommentRepository = function (axiosInstance) {
  let _CommentRepository = {
    getComments: async function (_key, _type, workplaceId, params) {
      try {
        //@todo: We should not normally handle reverse from repository based on the commentType
        //but the comments are not properly handled at the backend leaving us to provide this terrible hack
        //which we hope to change in the nearest future.
        //Please never do this except you necessarily need to
        const response = await axiosInstance.get(`/workflow/${workplaceId}/comments`, { params });
        return params.commentType === CommentType.COMMITTEE
          ? response.data
          : { ...response.data, data: response?.data?.data?.reverse() };
      } catch (error) {
        throw getApiError(error);
      }
    },

    getCommentDetails: async function (_key, commentId) {
      try {
        const response = await axiosInstance.get(`/comment/${commentId}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    postComment: async function (data) {
      try {
        const response = await axiosInstance.post('/comment', data);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
  };

  return _CommentRepository;
};

export { CommentRepository };
