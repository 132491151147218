import { getApiError } from '../utils/Api';

const WorkspaceRepository = function (axiosInstance) {
  let _WorkspaceRepository = {
    getWorkflowStats: async function () {
      try {
        const response = await axiosInstance.get('workflow/workflow-stats');
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getPendingConsolidatedPlans: async function () {
      try {
        const response = await axiosInstance.get('workflow/get-pending-consolidated-plan');
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getPendingNationalPlans: async function () {
      try {
        const response = await axiosInstance.get('workflow/national-plan/pending/count');
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getAllWorkflow: async function (_key, params) {
      try {
        const response = await axiosInstance.get('workflow', { params });
        return response.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getAllWorkflowByCommitteeId: async function (_key, committeeId, params) {
      try {
        const response = await axiosInstance.get(`workflow/committee/${committeeId}`, { params });
        return response.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    createWorkflow: async function (data) {
      try {
        const response = await axiosInstance.post('workflow', data);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getWorkflowById: async function (_key, id) {
      try {
        const response = await axiosInstance.get(`workflow/${id}`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    updateWorkflow: async function ({ id, data }) {
      try {
        const response = await axiosInstance.patch(`workflow/${id}`, data);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    approveWorkflow: async function (id) {
      try {
        const response = await axiosInstance.post(`workflow/${id}/approve`);
        return response?.data?.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    sendWorkflowForApproval: async function (id) {
      try {
        const response = await axiosInstance.get(`workflow/${id}/send-approval`);
        return response?.data?.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    shareWorkflowWithTwgs: async function ({ id, data }) {
      try {
        const response = await axiosInstance.post(`workflow/${id}/share-with-twg`, data);
        return response?.data?.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    rejectWorkflow: async function ({ id, rejectionNote }) {
      try {
        const response = await axiosInstance.post(
          `workflow/${id}/reject?rejectionNote=${rejectionNote}`,
        );
        return response?.data?.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    unpublishWorkflow: async function (id) {
      try {
        const response = await axiosInstance.post(`workflow/${id}/unpublish`);
        return response?.data?.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getWorkflowStatsForCitizens: async function () {
      try {
        const response = await axiosInstance.get('workflow/workflow-stats/citizen');
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getConsolidatedPlans: async function (_key, _, params) {
      try {
        const response = await axiosInstance.get('workflow/consolidated-plan', { params });
        return response.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getConsolidatedPlanById: async function (_key, _, id) {
      try {
        const response = await axiosInstance.get(`workflow/${id}/consolidated-plan-details`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getOverviewStats: async function () {
      try {
        const response = await axiosInstance.get(`workflow/cwg-overview`);
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },

    getCommentsTrend: async function (_key, _, params) {
      try {
        const response = await axiosInstance.get('workflow/comment-trend', { params });
        return response.data.data;
      } catch (error) {
        throw getApiError(error);
      }
    },
  };
  return _WorkspaceRepository;
};

export { WorkspaceRepository };
