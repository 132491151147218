import { notification } from 'antd';
import { useQuery } from 'react-query';

import { Api, resources } from '../utils/Api';
import Auth from '../utils/Auth';
import { UserRoles } from '../utils/Constants';

export const useAuthenticatedUser = () => {
  const { data: userDetails, isSuccess, isFetching: isloadingUser } = useQuery(
    [resources.CURRENT_USER],
    Api.AuthRepository.getCurrentUser,
    {
      enabled: Auth.isAuthenticated() && !Auth.belongsToCitizensRoutes(),
      cacheTime: 3600 * 1000,
      staleTime: 3600 * 1000,
      onError: ({ status, message }) => {
        notification.error({
          message: `Status code: ${status}`,
          description: `Error fetching user. ${message}`,
        });
      },
    },
  );
  const role = userDetails?.role?.toUpperCase();
  const isBioComplete = userDetails?.bioCompleted;
  return {
    userDetails,
    role: UserRoles[role],
    isBioComplete,
    isSuccess,
    isloadingUser,
  };
};
