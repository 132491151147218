export const DATA_LIMIT = 10;
export const DATE_FORMAT = 'DD MMM, YYYY';
export const TIME_FORMAT = 'hh:mm:ss';
export const DATETIME_FORMAT = 'YYYY-MM-DD';
export const CalendarDateFormat = {
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  nextWeek: 'dddd',
  lastDay: '[Yesterday]',
  lastWeek: '[Last] dddd',
  sameElse: 'Do MMM YYYY',
};
export const DocumentStatus = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
};
export const DocumentType = {
  BUDGET: 'BUDGET',
  NATIONAL_PLAN: 'NATIONAL_PLAN',
  MACRO_ECONOMIC_PLAN: 'MACRO_ECONOMIC_PLAN',
  CONSOLIDATED_NATIONAL_PLAN: 'CONSOLIDATED_NATIONAL_PLAN',
};
export const UserRoles = {
  TWG: 'TWG',
  SUPER_ADMIN: 'SUPERADMIN',
  CWG: 'CWG',
};
export const UserArea = {
  STATE: 'STATE',
  LGA: 'LGA',
};
export const CommentType = {
  CITIZEN: 'CITIZEN',
  COMMITTEE: 'COMMITTEE',
};
export const UserType = {
  COMMITTEE: 'committee',
  CITIZENS: 'citizens',
};
export const NATIONAL_PLAN_UPLOAD_FORMATS = '.pdf, .docx, .pptx';
export const DEFAULT_DOCUMENT_UPLOAD_FORMATS =
  '.pdf, .docx, .csv, .tsv, .xls, .xlsx, image/*, .pptx';
export const WORKSHEET_UPLOAD_FORMATS = '.xls, .xlsx, .csv';
export const BudgetStage = {
  BUDGET_UPLOADED: `BUDGET_UPLOADED`,
  PROJECT_UPLOADED: `PROJECT_UPLOADED`,
  PUBLISHED: `PUBLISHED`,
};
export const SettingsType = {
  MDA: 'mda',
  SUB_MDA: 'subMda',
};
export const MacroPlanSectors = {
  MONETARY_SECTOR: 'Monetary',
  EXTERNAL_SECTOR: 'External',
  REAL_SECTOR: 'Real',
  FISCAL_SECTOR: 'Fiscal',
  SOCIAL_SECTOR: 'Social',
};
export const MacroPlanIndicators = {
  GDP: 'GDP',
  INFLATION: 'Inflation',
  FOREIGN_RESERVES: 'ForeignReserves',
  EXTERNAL_DEBT: 'ExternalDebt',
  FOREIGN_DIRECT_INVESTMENT: 'ForeignDirectInvestment',
  NATIONAL_BUDGET: 'NationalBudget',
  NATIONAL_DEBT: 'NationalDebt',
  LIFE_EXPECTANCY: 'LifeExpectancy',
  INFANT_MORTALITY_RATE: 'InfantMortalityRate',
  UNEMPLOYMENT: 'Unemployment',
  PER_CAPITAL_INCOME: 'PerCapitaIncome',
};
export const MacroPlanValueTypes = {
  AMOUNT: 'Amount',
  PERCENTAGE: 'Percentage',
  NUMBER: 'Number',
  FRACTION: 'Fraction',
};
export const MacroPlanFrequency = {
  YEARLY: 'Yearly',
  QUARTERLY: 'Quarterly',
  MONTHLY: 'Monthly',
};
